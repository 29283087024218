import { createRouter, createWebHistory } from "vue-router";

export const constantRoutes = [
  {
    path: "/",
    component: () => import("../src/Home.vue"),
    name: "Dashboard",
  },
  {
    path: "/menu",
    component: () => import("../src/components/Menu.vue"),
    name: "Menu",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [...constantRoutes],
});

export default router;
