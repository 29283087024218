<template>
  <router-view></router-view>
</template>

<style>
html,
#app {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
body {
  margin: 0;
}
p,
ul,
li {
  margin: 0;
  padding: 0;
}
</style>
